import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components';
import Layout from "../components/layout"
import Image from "../components/image"
import Slideshow from "../components/slideshow"
import SEO from "../components/seo"
import Text from "../components/text"
import PortableText from "@sanity/block-content-to-react"
import serializer from "../utils/serializer"
import { 
  breakpoint,
  type_body, 
  type_body_sm,
  NewsletterWrap, 
  margin, 
  baseColor, 
  greyColor, 
  blackColor, 
  grid, 
  colorTransition, 
  type_caption, 
  entryPadding,
  footerHeightMobile
} from '../styles/helpers'
import { 
  PageWrap, 
  PageNav, 
  PageNavButton,
  PageNavInner,
  PageNavItem, 
  PageWrapInner, 
  PageEntry, 
  PageEntryTitle,
  PageEntryInner, 
  PageEntryMark 
} from '../styles/page'
import SubscribeFull from '../components/subscribeFull'
import Img from "gatsby-image"
import urlBuilder from "@sanity/image-url"
import { getFluidGatsbyImage, getFixedGatsbyImage } from 'gatsby-source-sanity'
import slugify from "../utils/slugify"
import arrowNorth from "../static/images/arrow-north.svg"
import arrowSouth from "../static/images/arrow-south.svg"


const LangSwitch = styled.div`
  width: 100%;
  margin-bottom: 1em;
  user-select: none;
  & .divider {
    color: ${greyColor};
    margin-top: -2px;
  }
`

const LangSwitchOption = styled.span`
  ${type_body};
  color: ${props => (props.isActive ? blackColor : greyColor)};
  cursor: pointer;
  transition: ${colorTransition};
  &.active {
    color: ${blackColor};
  }
  &:first-child {
    margin-right: ${margin};
  }
  &:last-child {
    margin-left: ${margin};
  }
  &:hover {
    color: ${blackColor};
  }
  @media ${breakpoint.xs} {
    font-size: 110%;
  }
`

const SocialWrap = styled.div`
  & span {
    width: 100%;
    max-width: 250px;
    text-align: center;
    ${type_body_sm};
    margin-bottom: .75em;
  }
`

const LinksWrap = styled.ul`
  ${type_body};
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100% !important;
  & li {
    list-style: none;
    margin-right: 2rem;
    @media ${breakpoint.xs} {
      margin-right: 1.25rem;
    }
    &:last-child {
      margin-right: 0;
    }
    & a {
      color: ${blackColor};
      transition: ${colorTransition};
      text-decoration: none;
      &:hover {
        color: ${greyColor};
      }
    }
  }
`

const SlideWrap = styled.figure`
  display: flex !important;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50%;
  // ...added (85vh is optical)
  // this should be consistent for all images
  max-height: 85vh;
  & img {
    margin-bottom: .5em;
    // should not need important flag here
    // this should be consistent for all images
    object-fit: contain !important;
  }
  @media ${breakpoint.xs} {
    // should not need important flag here
    width: 80% !important;
  }
  & figcaption {
    ${type_caption};
    white-space: pre-wrap;
    text-align: center;
    display: flex;
    align-items: center;
    transform: translateX(.5rem);
    & span#arrow {
      margin-left: .25rem;
      margin-top: .5rem;
      height: .7rem;
      width: .7rem;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
`

// shouldn't need !important flags here...
const LogosWrap = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1rem;
  display: flex;
  flex-flow: row !important;
  // margin-bottom: ${entryPadding};
  @media ${breakpoint.xs} {
    margin-bottom: ${footerHeightMobile};
  }
`

const LogosWrapEntry = styled.div`
  width: auto !important;
  &:first-child {
    margin-right: 1rem;
  }
  &:last-child {
    margin-left: 1rem;
  }
  & a {
    display: flex;
    height: 100%;
    width: 100%;
    & img {
      height: 40px !important;
      width: auto !important;
    }
  }
`

export const query = graphql`
  {
    allSanityAbout {
      edges {
        node {
          _rawEnglishText
          _rawDutchText
          _rawEnglishBio
          _rawDutchBio
          _rawOfficeImages
          entries {
            Title
            menu_title
            _rawContent
            Symbol
          }
          _rawCredits
          _rawSupport
          _rawSupportLogos
          supportLogos {
            image {
              _rawAsset
              _key
            }
            _type
            _key
            link
          }
          _rawWebsiteCredits
          links {
            _key
            _type
            link_text
            link_url
          }
        }
      }
    }
  }
`

const returnLinks = (links) => {
  return links.map((link, key) => {
    return <li><a href={link.link_url} target='_blank'>{link.link_text}</a></li>
  })
}



const AboutPage = ({ data, location, ...props }) => {

  const [dutch, setDutch] = useState(false)
  const [navOpen, setNavOpen] = useState(false)
  const [activeLink, setActiveLink] = useState('')
  const [slideCount, setSlideCount] = useState(0)
  const currentText = dutch ? data.allSanityAbout.edges[0].node._rawDutchText : data.allSanityAbout.edges[0].node._rawEnglishText
  const currentBio = dutch ? data.allSanityAbout.edges[0].node._rawDutchBio : data.allSanityAbout.edges[0].node._rawEnglishBio

  const firstNavItem = 'about'  
  const intersectionOptions = {
    root: null,
    // can be -60%
    rootMargin: '0px 0px -60% 0px',
    threshold: 0
  }

  // todo: move config data into .env file if still in use
  const sanityConfig = { projectId: 'ul6999hy', dataset: 'production' }


  useEffect(() => {

    props.mobile ? setNavOpen(false) : setNavOpen(true)

    // console.log('about data', data)
    setActiveLink(firstNavItem)
    const observer = new IntersectionObserver(observerCallback, intersectionOptions)
    // would be ideal to do this without timeout
    setTimeout(() => {
      const waypointTargets = document.querySelectorAll('#waypoint')
      if (waypointTargets.length) {
        waypointTargets.forEach((i, key) => {
          if (i) {
            observer.observe(i)
          }
        })
        // set observer bound here in local state?
      }
    }, 2000)
  },[])

  function observerCallback(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // ideally would use refs instead
        const id = entry.target.getAttribute('data-id')
        setActiveLink(id)
      }
    })
  }

  const handleEnClick = () => {
    setDutch(false)
  }

  const handleNlClick = () => {
    setDutch(true)
  }

  // this has been copypasted from projects.js
  // would ideal to make re-usable/keep consistent
  const handleNavClick = (id) => {
    // ideally would use refs here instead
    const el = document.getElementById(id)
    const top = el.getBoundingClientRect().top
    el.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
  }

  const entryLinks = !data.allSanityAbout.edges[0].node.entries.length ? false : data.allSanityAbout.edges[0].node.entries.map((entry, key) => {
    const menuTitle = entry.menu_title !== null || entry.menu_title ? entry.menu_title : entry.Title
    return (
      <PageNavItem isProject={false} isActive={slugify(entry.Title) === activeLink ? true : false} onClick={(event) => handleNavClick(slugify(entry.Title))}>{ menuTitle }</PageNavItem>
    )
  })

  const entryContent = !data.allSanityAbout.edges[0].node.entries.length ? false : data.allSanityAbout.edges[0].node.entries.map((entry, key) => {
    return (
      <PageEntry id={slugify(entry.Title)}>
        <PageEntryTitle alignLeft={false}>{entry.Title}</PageEntryTitle>
        <PageEntryInner id='waypoint' data-id={slugify(entry.Title)}>
          <PortableText blocks={entry._rawContent} serializers={serializer} />
        </PageEntryInner>
        <PageEntryMark>
          { entry.Symbol }
        </PageEntryMark>
      </PageEntry>
    )
  })

  const supportLogos = !data.allSanityAbout.edges[0].node._rawSupportLogos.length ? false : data.allSanityAbout.edges[0].node._rawSupportLogos.map((logo, key) => {
    const src = urlBuilder(sanityConfig).image(logo.image.asset._ref).url()
    return (
      <LogosWrapEntry>
        <a href={logo.link} target="_blank">
          <img src={src} />
        </a>
      </LogosWrapEntry>
    )
  })

  const toggleNav = () => {
    navOpen ? setNavOpen(false) : setNavOpen(true)
  }

  const navInner = navOpen ?
    <PageNavInner>
      <LangSwitch>
        <LangSwitchOption isActive={!dutch} onClick={(event) => { handleEnClick()}}>
          EN
        </LangSwitchOption>
        <span className='divider'>/</span>
        <LangSwitchOption isActive={dutch} onClick={(event) => { handleNlClick()}}>
          NL
        </LangSwitchOption>
      </LangSwitch>
      <ul>
        <PageNavItem onClick={(event) => handleNavClick(slugify('about'))} isActive={slugify('about') === activeLink ? true : false}>About</PageNavItem>
        <PageNavItem onClick={(event) => handleNavClick(slugify('people'))} isActive={slugify('people') === activeLink ? true : false}>People</PageNavItem>
        {entryLinks}
        <PageNavItem onClick={(event) => handleNavClick(slugify('contact'))} isActive={slugify('contact') === activeLink ? true : false}>Contact</PageNavItem>
        <PageNavItem onClick={(event) => handleNavClick(slugify('support'))} isActive={slugify('support') === activeLink ? true : false}>Support</PageNavItem>
      </ul>
    </PageNavInner>
    :
    false

  const navButton = props.mobile ? <PageNavButton navOpen={navOpen} onClick={(event) => toggleNav()}>{!navOpen ? "..." : "+"}</PageNavButton> : false

  return (
    <>
    <SEO title="About" />
    <PageWrap id='about'>
      {/* side nav */}
      <PageNav sticky={true} navOpen={navOpen}>
        {navButton}
        {navInner}
      </PageNav>

      <PageWrapInner columnWidth={8}>
        {/* centered content */}
        <PageEntry id='about' hideImages={true} noPadding={true}>
          <PageEntryTitle alignLeft={false}>Mission Statement</PageEntryTitle>
          <PageEntryInner id='waypoint' data-id='about'>
            <Text imageSize={'medium'} data={currentText} />
            <Slideshow officeImages={true} slides={data.allSanityAbout.edges[0].node._rawOfficeImages} />
          </PageEntryInner>
        </PageEntry>

        {/* entries */}
        {entryContent}

        <PageEntry id='people'>
          <PageEntryTitle alignLeft={false}>People</PageEntryTitle>
          <PageEntryInner id='waypoint' data-id='people'>
            <Text imageSize={'medium'} data={currentBio} />
            {/* <PageEntryMark>,</PageEntryMark> */}
          </PageEntryInner>
        </PageEntry>

        <PageEntry id='contact'>
          <PageEntryTitle alignLeft={false}>Contact</PageEntryTitle>
          <PageEntryInner id='waypoint' data-id='contact'>
            <NewsletterWrap>
              <SubscribeFull message='You can keep up with the dormant office’s activities via:' />
            </NewsletterWrap>
            <SocialWrap>
              <span>Follow us elsewhere</span>
              <LinksWrap>
                {returnLinks(data.allSanityAbout.edges[0].node.links)}
              </LinksWrap>
            </SocialWrap>

          </PageEntryInner>
        </PageEntry>

        <PageEntry id='support'>
          <PageEntryTitle alignLeft={false}>Support the dormant office</PageEntryTitle>
          <PageEntryInner id='waypoint' data-id='support'>
            <Text imageSize={'small'} data={data.allSanityAbout.edges[0].node._rawSupport} />
          </PageEntryInner>
        </PageEntry>

        <PageEntry id='credits'>
          {/* <PageEntryTitle alignLeft={false}>Credits</PageEntryTitle> */}
          <PageEntryInner id='waypoint' data-id='credits'>
            <Text imageSize={'small'} data={data.allSanityAbout.edges[0].node._rawWebsiteCredits} />
            <LogosWrap>
              {supportLogos}
            </LogosWrap>
          </PageEntryInner>
        </PageEntry>

      </PageWrapInner>
    </PageWrap>
    </>
  )
}

export default AboutPage
