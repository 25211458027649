import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components';
import Time from '../utils/time'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { CSSTransition } from "react-transition-group";
import { 
  type_body, 
  type_body_sm,
  baseColor, 
  blackColor, 
  greyColor, 
  colorTransition, 
  opacityTransition, 
  blurTransition 
} from '../styles/helpers'


const SubscribeWrap = styled.div`
  position: relative;
  width: 100%;
  // height: 2rem;
`

const SubscribeLabel = styled.span`
  width: 100%;
  max-width: 250px;
  text-align: center;
  ${type_body_sm};
`

const SubscribeForm = styled.form`
  // position: absolute;
  // top: 0;
  transition: ${opacityTransition};
  // height: 100%;
  pointer-events: ${props => (props.isSubmitted ? `none` : `all`)};
  opacity: ${props => (props.isSubmitted ? `0` : `1`)};
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`

// padding-top is optical
const FormWrap = styled.div`
  display: flex;
  width: 100%;
  & input {
    width: 100%;
    max-width: 250px;
    padding: .35rem .35rem .125rem .35rem;
    outline: none;
    border: solid black 1px;
    margin-bottom: .35em;
    &:last-child {
      margin-bottom: .7em;
    }
    ${type_body_sm};
    &:focus{
      outline: none;
    }
  }
  & button {
    ${type_body_sm};
    width: 100%;
    max-width: 250px;
    padding: .35rem .35rem .125rem .35rem;
    outline: none;
    // same as colorTransition
    transition: background .25s linear;
    border: solid black 1px;
    &:focus {
      outline: none;
    }
    @media (hover: hover) {
      transition all .2s linear !important;
      &:hover {
        background: ${blackColor};
        color: ${baseColor};
      }
    }
  }
`

const SubscribeMsg = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  transition: ${opacityTransition};
  pointer-events: ${props => (props.isSubmitted ? `all` : `none`)};
  opacity: ${props => (props.isSubmitted ? `1` : `0`)};
  display: flex;
  align-items: center;
`

const SubscribeMsgInner = styled.div`
  padding-top: 1px;
`

const FormBackBtn = styled.div`
  display: inline-block;
  transition: ${colorTransition};
  color: ${blackColor};
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: ${greyColor};
    }
  }
`

class SubscribeFull extends React.Component {
  constructor(props) {
    super(props)
    this.successMsg = 'Thanks for subscribing!'
    this.firstNamePlaceholderMsg = 'First Name'
    this.lastNamePlaceholderMsg = 'Last Name'
    this.emailPlaceholderMsg = '* Email Address'
    this.subscribedMsg = 'You are already subscribed!'
    this.invalidMsg = 'Please use a valid email address'
    this.firstNameInputRef = React.createRef()
    this.lastNameInputRef = React.createRef()
    this.emailInputRef = React.createRef()
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      isSubmitted: false,
      isSuccesful: false,
      isSubscribed: false,
      responseText: '',
      firstNamePlaceholder: this.firstNamePlaceholderMsg,
      lastNamePlaceholder: this.lastNamePlaceholderMsg,
      emailPlaceholder: this.emailPlaceholderMsg
    }
  }

  // first name
  handleFirstNameFocus(e) {
    this.setState({
      firstNamePlaceholder: ''
    })
  }

  handleFirstNameBlur(e) {
    this.setState({
      firstNamePlaceholder: this.firstNamePlaceholderMsg
    })
  }

  handleFirstNameChange(e) {
    this.setState({
      firstName: e.currentTarget.value
    })
  }

  // last name 
  handleLastNameFocus(e) {
    this.setState({
      lastNamePlaceholder: ''
    })
  }

  handleLastNameBlur(e) {
    this.setState({
      lastNamePlaceholder: this.lastNamePlaceholderMsg
    })
  }

  handleLastNameChange(e) {
    this.setState({
      lastName: e.currentTarget.value
    })
  }

  // email
  handleEmailFocus(e) {
    this.setState({
      emailPlaceholder: ''
    })
  }

  handleEmailBlur(e) {
    this.setState({
      emailPlaceholder: this.emailPlaceholderMsg
    })
  }
  
  handleEmailChange(e) {
    this.setState({
      email: e.currentTarget.value
    })
  }

  resetForm(e) {
    this.setState({
      name: '',
      email: '',
      isSubmitted: false,
      isSuccesful: false,
      isSubscribed: false,
      responseText: '',
      firstNamePlaceholder: this.firstNamePlaceholderMsg,
      lastNamePlaceholder: this.lastNamePlaceholderMsg,
      emailPlaceholder: this.emailPlaceholderMsg
    })
    this.firstNameInputRef.current.blur()
    this.lastNameInputRef.current.blur()
    this.emailInputRef.current.blur()
  } 

  handleSubmit(e, email, firstName, lastName) {
    const self = this
    e.preventDefault()
    addToMailchimp(email, {
      FNAME: firstName,
      LNAME: lastName
    })
      .then((data) => {
        self.setState({
          isSubmitted: true
        })
        if (data.result === 'error') {
          // gatsby-plugin-mailchimp does not return status/specific callback
          // so having to read string instead
          if (data.msg.includes('already subscribed')) {
            self.setState({
              responseText: self.subscribedMsg,
              isSubscribed: true
            })
          }
          else {
            self.setState({
              responseText: self.invalidMsg
            })
          }
        }
        else {
          self.setState({
            responseText: self.successMsg,
            isSuccesful: true
          })
        }
      })
      .catch((error) => {
        self.setState({
          name: '',
          email: '',
          isSubmitted: false
        })
        return false
      });
  }

  render() {
    const self = this
    const returnBtn = !this.state.isSuccesful && !this.state.isSubscribed ? 
      <FormBackBtn onClick={(event) => self.resetForm(event)}>← Try Again</FormBackBtn> 
      : 
    false

    return (
      <SubscribeWrap>
        {/* will change this text */}
        <SubscribeLabel>{this.props.message}</SubscribeLabel>
        <SubscribeForm isSubmitted={this.state.isSubmitted} onSubmit={(event) => { self.handleSubmit(event, self.state.email, self.state.firstName, self.state.lastName)}}>
          <FormWrap>
            <input ref={this.firstNameInputRef} onBlur={(event) => { self.handleFirstNameBlur(event)}} onFocus={(event) => { self.handleFirstNameFocus(event)}} placeholder={this.state.firstNamePlaceholder} name="first_name" type="text" onChange={(event) => { self.handleFirstNameChange(event)}} />
            <input ref={this.lastNameInputRef} onBlur={(event) => { self.handleLastNameBlur(event)}} onFocus={(event) => { self.handleLastNameFocus(event)}} placeholder={this.state.lastNamePlaceholder} name="last_name" type="text" onChange={(event) => { self.handleLastNameChange(event)}} />
            <input ref={this.emailInputRef} onBlur={(event) => { self.handleEmailBlur(event)}} onFocus={(event) => { self.handleEmailFocus(event)}} placeholder={this.state.emailPlaceholder} name="email" type="text" onChange={(event) => { self.handleEmailChange(event)}} />
          </FormWrap>
          <FormWrap>
            <button type='submit'>Subscribe</button>
          </FormWrap>
        </SubscribeForm>
        <SubscribeMsg isSubmitted={this.state.isSubmitted}>
          <SubscribeMsgInner>
            <div>{this.state.responseText}</div>
            {returnBtn}
          </SubscribeMsgInner>
        </SubscribeMsg>
      </SubscribeWrap>
    )
  }
}


export default SubscribeFull
