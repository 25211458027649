import styled, { css } from "styled-components"
import { 
  type_general, 
  type_body, 
  type_body_bold, 
  type_body_sm, 
  type_title,
  type_title_sm,
  type_label,
  breakpoint, 
  eyeSize, 
  verticalMargin, 
  verticalMarginMobile, 
  margin, 
  greyColor, 
  blackColor, 
  baseColor, 
  grid, 
  colorTransition,
  entryPadding,
  headerHeight
} from './helpers'

// ...v2
export const PageWrap = styled.div`
  ${grid};
  padding: ${verticalMargin} ${margin} ${verticalMargin} ${margin};
  position: ${props => (props.fixed ? 'fixed' : 'relative')};
  height: ${props => (props.fixed ? '100%' : 'auto')};
  overflow: ${props => (props.fixed ? 'scroll' : 'unset')};
  width: 100%;
  @media ${breakpoint.xs} {
    padding: ${verticalMarginMobile} calc(${margin} * 2) ${verticalMarginMobile} calc(${margin} * 2);
    &#about {
      padding: 0 calc(${margin} * 2) ${verticalMarginMobile} calc(${margin} * 2);
    }
    &#project {
      padding: 0 calc(${margin} * 2) ${verticalMarginMobile} calc(${margin} * 2);
    }
    &#booking {
      padding: 0 calc(${margin} * 2) ${verticalMarginMobile} calc(${margin} * 2);
    }
  }
`

export const PageWrapInner = styled.div`
  ${type_body};
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  margin: 0 auto;
  grid-column-start: ${props => (props.columnWidth === 8 ? 3 : 1)};
  grid-column-end: ${props => (props.columnWidth === 8 ? 11 : 13)};
  // max-width: 900px;
  max-width: ${props => (props.columnWidth === 8 ? '900px' : '1400px')};
  justify-content: ${props => (props.centerItems ? 'center' : 'unset')};
  @media ${breakpoint.xs} {
    grid-column-start: 1;
    grid-column-end: 13;
  }
`

// padding left is calculated so it aligns with 'Menu'
export const PageNav = styled.div`
  display: flex;
  flex-flow: column;
  position: sticky;
  top: ${verticalMargin};
  grid-column-start: 1;
  grid-column-end: 3;
  padding-left: calc(${eyeSize} + (${margin}) * 2);
  align-self: flex-start;
  align-items: flex-start;
  @media ${breakpoint.xs} {
    grid-column-start: 1;
    grid-column-end: 13;
    background: white;
    background: ${props => (props.navOpen ? 'white' : 'linear-gradient(180deg,rgba(255,255,255,1) 0%,rgba(255,255,255,0.9) 50%,rgba(255,255,255,0) 100%);')};
    top: 0;
    padding-top: ${margin};
    padding-bottom: ${props => (!props.navOpen ? '1rem' : '1.25rem')};
    margin-top: ${props => (!props.sticky ? '.5em' : 'unset')};
    padding-left: unset;
    margin-bottom: ${props => (props.sticky ? verticalMarginMobile : '1em')};
    position: ${props => (props.sticky ? 'sticky' : 'relative')};
    z-index: 100;
  }
`



export const PageNavInner = styled.div`
  // background: yellow;
`

export const PageNavButton = styled.div`
  // background: green;
  cursor: pointer;
  text-transform: capitalize;
  // ${type_general};
  // font-family: 'LarsMono';
  font-size: ${props => props.navOpen ? '2rem' : '1.5rem'};
  user-select: none;
  margin-bottom: ${props => props.navOpen ? '.125em' : '1em'};
  letter-spacing: ${props => props.navOpen ? 'normal' : '-.3em'};
  font-family: ${props => props.navOpen ? 'LarsMono' : 'LarsMono'};
  transform: ${props => props.navOpen ? 'rotate(45deg) translateX(-15%)' : ''};
  & span {
    font-size: 75%;
  }
  @media ${breakpoint.xs} {
    font-size: ${props => props.navOpen ? '2.5rem' : '2rem'};
  }
`

export const PageNavItem = styled.li`
    ${type_general};
    list-style: none;
    color: ${props => (props.isActive || props.title ? 'black' : greyColor)};
    margin-left: ${props => !props.title && props.projectIndex ? '1em' : 0};
    transition: ${colorTransition};
    cursor: pointer;
    user-select: none;
    @media (hover: hover) {
      &:hover {
        color: black;
      }
    }
    &:last-child {
      // margin-top: ${props => (props.isProject ? '1.5em' : 0)};
    }
    & a {
      color: ${greyColor};
      transition: ${colorTransition};
      @media ${breakpoint.xs} {
        &.back-btn {
          color: ${blackColor};
        }
      }
      @media (hover: hover) {
        &:hover {
          color: black;
        }
      }
    }
    @media ${breakpoint.xs} {
      font-size: 110%;
      margin-bottom: .25rem;
    }
  }
`


// ...todo: remove hideImages prop in figure (for dev only)
export const PageEntry = styled.section`
  width: 100%;
  padding-top: ${props => (props.noPadding ? '0' : entryPadding)};
  // -7.5rem accounts for 12.5rem entry padding
  scroll-margin-top: ${props => (props.noPadding ? '5rem' : '-7.5rem')};
  display: flex;
  flex-flow: column;
  align-items: center;
  &:last-child {
    // padding-bottom: 5rem;
  }
  & div {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    & p {
      width: 65%;
      max-width: 500px;
      @media ${breakpoint.xs} {
        width: 100%;
      }
      @media ${breakpoint.sm} {
        width: 80%;
      }
      @media ${breakpoint.xl} {
        width: 50%;
        max-width: 800px;
      }
      @media ${breakpoint.xxl} {
        width: 50%;
        max-width: 800px;
      }
    }
    & p + figure {
      // padding-top: 2rem;
      padding-top: 1rem;
    }
    & figure + p {
      padding-top: 2rem;
    }
    & figure {
      // ...new
      width: 100%;
      // width: ${props => (props.fullWidthImages ? '65%' : '50%')};
      // added (same a text)
      max-width: 500px;
      margin-bottom: 1rem;
      // temp
      display: ${props => (props.hideImages ? 'none' : 'flex')};
      flex-flow: column;
      @media ${breakpoint.xs} {
        // width: 80%;
        width: 100%;
      }
    }
  }
`

export const PageEntryInner = styled.div`
  width: 100%;
  & div {
    flex-flow: column;
    display: flex;
    justify-content: center;
  }
  & * > p {
    margin-bottom: 1em;
    ${type_body};
    &:last-child {
      margin-bottom: 0;
    }
    & strong {
      ${type_body_bold};
    }
    & a {
      color: black;
      text-decoration: underline;
      transition: ${colorTransition};
      @media (hover: hover) {
        &:hover {
          color: ${greyColor};
        }
      }
    }
  }
  // temp fix for cta button
  & button {
    align-self: center;
  }
  // temp fix for project text
  & ul {
    max-width: 500px;
    width: 60%;
    & li {
      font-size: 85%;
    }
  }
  & a {
    color: black;
    text-decoration: underline;
    transition: ${colorTransition};
    @media (hover: hover) {
      &:hover {
        color: ${greyColor};
      }
    }
  }
`

// w.i.p
export const PageEntryTitle = styled.h1`
  ${type_title_sm};
  // font-size: 120%;
  margin-bottom: 2em;
  align-self: ${props => (props.alignLeft ? 'flex-start' : 'unset')};
`


// w.i.p
export const PageEntryMark = styled.div`
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-family: 'LarsMono';
  padding: 5rem 0 1.5rem 0;
  line-height: .5;
  margin-bottom: ${props => (props.marginBottom ? '5rem' : '0')};
`

// .....
// .....

// ...v1 
export const SinglePageWrap = styled.div`
  ${grid};
  background: ${baseColor};
`

export const SinglePageClose = styled.div`
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  justify-content: flex-end;
  margin: calc(${margin} * 4) calc(${margin} * 4) 0 0;
  position: relative;
  top: 0;
 & a {
   color: black;
   transition: ${colorTransition};
   ${type_label};
   color: ${blackColor};
   cursor: pointer;
   @media (hover: hover) {
    &:hover {
      color: ${greyColor};
    }
  }
 }
`

// added flex properties here to center booking checkout
export const SinglePageWrapInner = styled.div`
  margin: ${props => (props.isBooking ? '0' : 'auto')};
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  @media ${breakpoint.xs} {
    padding: 1.5rem calc(${margin} * 2) 2rem calc(${margin} * 2);
    grid-column-start: 1;
    grid-column-end: 13;
    max-width: ${props => (props.isBooking ? 'unset' : '400px')};
  }
  @media ${breakpoint.landscape} {
    padding: 2rem calc(${margin} * 2) 2rem calc(${margin} * 2);
    grid-column-start: 3;
    grid-column-end: 11;
  }
  @media ${breakpoint.sm} {
    padding: 2rem 0 2rem 0;
    grid-column-start: 4;
    grid-column-end: 10;
  }
  @media ${breakpoint.md} {
    padding: 2rem calc(${margin} * 2) 2rem calc(${margin} * 2);
    grid-column-start: 4;
    grid-column-end: 10;
    max-width: ${props => (props.isBooking ? 'unset' : '500px')};
  }
  @media ${breakpoint.lg} {
    padding: 2rem 0 2rem 0;
    grid-column-start: 4;
    grid-column-end: 10;
    max-width: ${props => (props.isBooking ? 'unset' : '500px')};
  }
  @media ${breakpoint.xl} {
    padding: 2rem 0 2rem 0;
    grid-column-start: 4;
    grid-column-end: 10;
    max-width: ${props => (props.isBooking ? 'unset' : '600px')};
  }
  @media ${breakpoint.xxl} {
    padding: 2rem 0 2rem 0;
    grid-column-start: 4;
    grid-column-end: 10;
    max-width: ${props => (props.isBooking ? 'unset' : '600px')};
  }
`